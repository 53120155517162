export const EMOJIS = [
	{code: "joy", icon: "😂"},
	{code: "100", icon: "💯"},
	{code: "tada", icon: "🎉"},
	{code: "wow", icon: "😮"},
	{code: "prayerhands", icon: "🙏"},
	{code: "hearteyes", icon: "😍"},
	{code: "crying", icon: "😢"},
	{code: "thumbsup", icon: "👍"},
	{code: "thumbsdown", icon: "👎"},
]

export const EMOJI_ICONS = (() => {
	const mapping = {};
	for(let i=0; i<EMOJIS.length; i++) {
		mapping[EMOJIS[i].code] = EMOJIS[i].icon;
	}
	return mapping;
})();