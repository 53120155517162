import React, { useState, useRef } from 'react';
import { EMOJI_ICONS } from './constants';
import 'animate.css';

function EmojiButton({ onClick, emojiCode }) {
    const [isAnimating, setIsAnimating] = useState(false);
    const element = useRef(null);

    const handleAnimationEnd = () => {
        element.current.removeEventListener('animationend', handleAnimationEnd);
        setIsAnimating(false);
    };
    const handleClick = () => {
        element.current.addEventListener('animationend', handleAnimationEnd);
        setIsAnimating(true);
        onClick(emojiCode);
    };
    let classes = "emoji-button";
    if (isAnimating) {
      classes = 'emoji-button animate__animated animate__pulse animate__faster';
    }
    return (
        <button className={classes} onClick={() => { handleClick(emojiCode) }} ref={element}>
            <span role="img" aria-label={emojiCode}>{EMOJI_ICONS[emojiCode]}</span>
        </button>
    );
}

export default EmojiButton;
