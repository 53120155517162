import React, { useEffect, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';
import './App.css';
import AudiencePanel from './AudiencePanel';
import PresentationPanel from './PresentationPanel';

function App() {
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    let curUserId = window.localStorage.getItem('uid');
    if(curUserId === null) {
      curUserId = uuidv4();
      window.localStorage.setItem('uid', curUserId);
    }
    setUserId(curUserId);
  }, []);

  const viewName = window.location.pathname.substring(1, window.location.pathname.length);
  switch(viewName) {
    case '_present':
      return (
        <PresentationPanel
          userId={userId}
        />
      )
    default:
      return (
        <AudiencePanel
          userId={userId}
          channelId={viewName}
        />
      )
  }
}

export default App;
