import React from 'react';
import './App.css';
import { client } from './streaming-client';
import EmojiButton from './EmojiButton';
import { EMOJI_ICONS } from './constants';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';

function AudiencePanel({ userId, channelId }) {

  const handleClick = (emojiCode) => {
    const channel = client.channels.get(channelId);
    channel.publish("reaction", {
      emoji: emojiCode,
      uid: userId,
    });
  };

  return (
    <Container>
      <Jumbotron>
        <h1>Slidespruce</h1>
        <p>Want to use slidespruce for your next presentation? Join our private beta.</p>
        <p>{`You are connected to: ${channelId}`}</p>
      </Jumbotron>
      <div className="audience-view">
        {Object.keys(EMOJI_ICONS).map((emojiCode) => {
          return (
            <EmojiButton
              onClick={handleClick}
              emojiCode={emojiCode}
              key={emojiCode}
            />
          )
        })}
      </div>
    </Container>
  );
}

export default AudiencePanel;
