import React, { useReducer, useEffect, useState, useRef } from 'react';
import './App.css';
import { client } from './streaming-client';
import * as qs from 'query-string';
import { EMOJI_ICONS } from './constants';
import 'animate.css';


const EMOJI_TIMEOUT = 15000;


const reactionRecuder = (prevReactions, action) => {
    const newReactions = { ...prevReactions };
    const emojiCode = action.emojiCode;
    const cur = prevReactions[emojiCode];
    switch (action.type) {
        case 'increment':
            if (typeof EMOJI_ICONS[emojiCode] === 'undefined') {
                return prevReactions;
            }
            const uid = action.uid;
            let newUsers = {};
            if (typeof cur !== 'undefined') {
                if (typeof cur.users[uid] !== 'undefined') {
                    return prevReactions;
                }
                newUsers = {...cur.users};
            }
            newUsers[uid] = 1;
            newReactions[emojiCode] = {
                users: newUsers,
                lastUpdated: new Date(),
            };
            return newReactions;
        case 'clear':
            if (typeof cur !== 'undefined') {
                delete newReactions[emojiCode];
                return newReactions;
            } else {
                return prevReactions;
            }
        default:
            throw new Error();
    }
}


function PresentationPanel(props) {
    const [reactions, dispatch] = useReducer(reactionRecuder, {});
    const [channelId, setChannelId] = useState(null);
    const timersRef = useRef({});
    const channelRef = useRef(null);

    useEffect(() => {
        document.body.style.background = "#000000";
    }, []);

    useEffect(() => {
        if (typeof props.userId === 'undefined' || props.userId === null) {
            return
        }
        const params = qs.parse(window.location.search);
        const listener = (rawMessage) => {
            dispatch({type: 'increment', emojiCode: rawMessage.data.emoji, uid: rawMessage.data.uid});
        };
        async function fetchData() {
            const response = await fetch('/.netlify/functions/channel-create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                body: JSON.stringify({
                    url: params.url,
                    userId: props.userId,
                })
            });
            const jsonData = await response.json();
            const retChannelId = jsonData.channelId;
            setChannelId(retChannelId);
            channelRef.current = client.channels.get(retChannelId);
            channelRef.current.subscribe('reaction', listener);
        }
        fetchData();
        return () => {
            if (channelRef.current) {
                channelRef.current.unsubscribe('reaction', listener);
            }
        };
    }, [props.userId]);

    useEffect(() => {
        const timers = timersRef.current;
        Object.keys(EMOJI_ICONS).forEach((emojiCode) => {
            const curTimer = timers[emojiCode];
            const curReaction = reactions[emojiCode];
            if (typeof curTimer === 'undefined' && typeof curReaction === 'undefined') {
                return;
            }
            if (typeof curTimer !== 'undefined' && typeof curReaction !== 'undefined') {
                if (curReaction.lastUpdated.getTime() === curTimer.lastUpdated.getTime()) {
                    return
                }
            }
            if (typeof curTimer !== 'undefined') {
                clearTimeout(curTimer.timer);
                delete timers[emojiCode];
            }
            if (typeof curReaction === 'undefined') {
                return;
            }
            const now = new Date();
            const msUntilTimeout = curReaction.lastUpdated.getTime() + EMOJI_TIMEOUT - now.getTime();
            if (msUntilTimeout <= 0) {
                return;
            }
            const timerHandle = setTimeout(() => {
                dispatch({type: 'clear', emojiCode: emojiCode});
            }, msUntilTimeout);
            timers[emojiCode] = {
                timer: timerHandle,
                lastUpdated: curReaction.lastUpdated,
            }
        });
    }, [reactions]);

    return (
        <div className="presenter-view">
            {Object.keys(reactions).map((emojiCode) => {
                const count = Object.keys(reactions[emojiCode].users).length;
                return (
                    <span className="presenter-emoji-container animate__animated animate__bounceIn" key={emojiCode}>
                        <span className="presenter-emoji" data-presenter-badge={count}>
                            {EMOJI_ICONS[emojiCode]}
                        </span>
                    </span>
                )
            })}
            {channelId && (
                <div className="presenter-label">
                    React to this presentation!<br />
                    {`https://slidespruce.com/${channelId}`}
                </div>
            )}
        </div>
    );
}

export default PresentationPanel;